import { graphql } from 'gatsby'
import React, { useCallback, useRef } from 'react'
import { Footer, Header, Layout, Nav } from '../components'
import { About } from '../containers'

const AboutPage = ({ data }) => {
	return (
		<Layout>
			<About data={data} />
		</Layout>
	)
}

export default AboutPage

export const pageQuery = graphql`
	query {
		allImageSharp {
			edges {
				node {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
		}
	}
`
